<template>
	<b-card title="Vendor List">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">User :</label>
					<input 
						id="name"
						type="text" 
						class="form-control"
						placeholder="Search: user id"
						v-model="filter.user_id" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">User :</label>
					<input 
						id="name"
						type="number" 
						class="form-control"
						placeholder="Search: vendor id"
						v-model="filter.vendor_id" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label>Date:</label>
					<flat-pickr
						v-model="filter.date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
						maxDate: (() => {
							let date = new Date();
							date.setDate(date.getDate() - 1);
							return date;
						})(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button class="btn btn-outline-secondary mr-2" @click="clearFilter">
						Reset
				</button>
				<!-- <button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export
					@click="getDataExport"
				>
					Export
				</button> -->
				
			</b-col>
			<!-- <Export
				:is-loading="isLoading"
				:result-export="resultExport"
				:export-now="exportNow"
			/> -->
		</b-row>
		
		<Table 
			:result="result"
			:konsultasi="konsultasi"
			:is-loading="isLoading"
			:get-data="getData"
			:current-page="currentPage"
			:format-date="formatDate"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/Export.vue'
import Table from '@/components/vendor-konsultasi/table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			isLoading: false,
			result: [],
			link:'',
			konsultasi: [],
			// isLoadingExport: false,
			// resultExport: [],
			sort: '',
			filter: {
				user_id: '',
				vendor_id: '',
				date: '',
			},
			api: {
				vendor_booking:'/admin/vendor-booking',
			}
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	created() {
		this.getData()
	},
	// mounted() {

	// 	this.getProvince();
	// 	this.categoryName();
	// 	// this.getCity();
	// },
	methods: {
		// getDataExport() {
		// 	this.isLoadingExport = true
		// 	this.$http.get('/admin/export/vendor/list'
		// 	).then(response => {
		// 		this.resultExport = response.data.data
		// 		this.isLoadingExport = false
		// 	}).catch(error => {
		// 	})
		// },
		clearFilter() {
			this.filter = {
				user_id: '',
				vendor_id: '',
				date: '',
			}
		},
		// categoryName() {
		// 	this.$http.get('/admin/vendor-category')
		// 	.then(response => {
		// 		this.category = response.data.data
		// 	})
		// },
		// exportNow() {
		// 	this.isLoadingExport = true
		// 	const exportParam = this.filter
		// 	exportParam.per_page = null
		// 	this.$http.post('/admin/export/vendor/export', exportParam).then(response => {
		// 		if(response) {
		// 			this.$swal({
		// 				title: 'Sukses',
		// 				icon: 'success',
		// 				text: 'Mengexport data di background, mohon tunggu beberapa saat.',
		// 				type: 'warning',
		// 				customClass: {
		// 					confirmButton: 'btn btn-primary',
		// 				},
		// 				buttonsStyling: false,
		// 			})
		// 			this.$bvModal.hide('form-modal-export')
		// 			this.isLoadingExport = false
		// 		}
		// 	}).catch(error => {
		// 		if (error.response.data.meta.validations) {
		// 			this.validations = error.response.data.meta.validatiosns
		// 		}
		// 	})
		// },
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			this.$http.get(this.api.vendor_booking, {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.konsultasi = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})
		},
		// deleteItem(slug) {
		// 	this.$swal({
		// 		title: 'Are you sure?',
		// 		text: 'Are you sure to delete this vendor?',
		// 		icon: 'warning',
		// 		showCancelButton: true,
		// 		customClass: {
		// 		confirmButton: 'btn btn-primary',
		// 		cancelButton: 'btn btn-outline-danger ml-1',
		// 		},
		// 		buttonsStyling: false,
		// 	}).then(result => {
		// 		if (result.value) {
		// 		this.isLoading = true
		// 		this.$http.delete(`/admin/vendor/${slug}`)
		// 			.then(response => {
		// 			this.getData(this.currentPage)
		// 			this.$swal({
		// 				icon: 'success',
		// 				title: 'Success!',
		// 				text: 'Vendor successfully deleted',
		// 				customClass: {
		// 				confirmButton: 'btn btn-success',
		// 				},
		// 			})
		// 			this.isLoading = false
		// 			})
		// 			.catch(error => {
		// 			if (error.response.data.meta.messages.length > 0) {
		// 				errorNotification(this, 'Oops!', error.response.data.meta.messages)
		// 			}
		// 			})
		// 		}
		// 	})
		// },
		// updateFlag(id, status) {
		// 	let pinText = status === true ? 'pin' : 'unpin';
		// 	this.$swal({
		// 		title: 'Are you sure?',
		// 		text: `Are you sure to ${pinText} this vendor?`,
		// 		icon: 'warning',
		// 		showCancelButton: true,
		// 		customClass: {
		// 		confirmButton: 'btn btn-primary',
		// 		cancelButton: 'btn btn-outline-danger ml-1',
		// 		},
		// 		buttonsStyling: false,
		// 	}).then(result => {
		// 		if (result.value) {
		// 			let payload = new FormData();
		// 			payload.append('is_pinned',  status)
		// 			this.isLoading = true
		// 			this.$http.post(`/admin/vendor/set-pin-content/${id}`, payload, {
		// 			headers: { "content-type": "multipart/form-data" },
		// 		})
		// 			.then(response => {
		// 				this.getData(this.currentPage);
		// 				this.$swal({
		// 						icon: 'success',
		// 						title: 'Success!',
		// 						text: 'Vendor successfully updated',
		// 						customClass: {
		// 						confirmButton: 'btn btn-success',
		// 					},
		// 				});
				
		// 				this.isLoading = false
		// 			})
		// 			.catch(error => {
		// 				if (error.response.data.meta.messages.length > 0) {
		// 					errorNotification(this, 'Oops!', error.response.data.meta.messages)
		// 					this.getData(this.currentPage);
		// 					this.isLoading = false
		// 				}
		// 			})
		// 		}
		// 	})
		// },
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>