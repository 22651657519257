var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Vendor List"}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("User :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.user_id),expression:"filter.user_id"}],staticClass:"form-control",attrs:{"id":"name","type":"text","placeholder":"Search: user id"},domProps:{"value":(_vm.filter.user_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "user_id", $event.target.value)}}})])]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("User :")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.vendor_id),expression:"filter.vendor_id"}],staticClass:"form-control",attrs:{"id":"name","type":"number","placeholder":"Search: vendor id"},domProps:{"value":(_vm.filter.vendor_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "vendor_id", $event.target.value)}}})])]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Search: Date","config":{ 
					maxDate: (function () {
						var date = new Date();
						date.setDate(date.getDate() - 1);
						return date;
					})(),
						locale: {
							rangeSeparator: ' - ',
						},
					}},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1)]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn btn-outline-secondary mr-2",on:{"click":_vm.clearFilter}},[_vm._v(" Reset ")])])],1),_c('Table',{attrs:{"result":_vm.result,"konsultasi":_vm.konsultasi,"is-loading":_vm.isLoading,"get-data":_vm.getData,"current-page":_vm.currentPage,"format-date":_vm.formatDate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }